import React from 'react'
import { graphql } from 'gatsby'
import { faAutoprefixer } from '@fortawesome/free-brands-svg-icons'
import Sidebar from '../components/Sidebar'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'


class Contact extends React.Component {
  render() {
    console.log('contact this.props', this.props)

    // const { location } = this.props
    // const {
    //   author,
    //   authorLinks,
    //   subtitle,
    //   copyright,
    //   menu,
    // } = this.props.data.site.siteMetadata
    return (
      <Layout>
        <div>
          <Sidebar
            {...this.props}
          />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">Get In Touch</h1>
                <div
                  className="page__body"
                  style={{ margin: '0 auto', alignItems: 'center' }}
                >
                  <p>
                    If you want to contact me, feel free, here’s how to do it:
                  </p>
                  <ContactForm />

                  {/* <p>You can also find me on Facebook, Twitter or LinkedIn</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}


// TODO:
// create my gatsby starter template
// add robots to form
// change email
// create utils library
// create posts
// add discus from https://github.com/duffn/blog
// add toolbox from: https://github.com/greglobinski/www.greglobinski.com
// add contact links like that https://www.greglobinski.com/contact/
// add repo computer setup https://www.greglobinski.com/contact/
// add playground
// add https://transitionlink.tylerbarnes.ca/
// add font mono jetbrains
// https://overreacted.io/ add black or white
// resume vs cv https://theundercoverrecruiter.com/cv-vs-resume-difference-and-when-use-which/
// Theme UI

// Resume:
// https://piktochart.com/creative-resume-ideas/?utm_source=outreach&utm_medium=quora&utm_campaign=10x-resume&utm_content=10x-resume-home


export default Contact

export const contactPageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
          icon
        }
        author {
          name
          email
          linkedin
          twitter
          github
          twitch
          codepen
        }
        authorLinks {
          social
          socialUrl
          socialIcon
        }
      }
    }
  }
`
