import React from 'react'
import { navigate } from 'gatsby-link'
//import Recaptcha from 'react-google-recaptcha'
import styled from 'styled-components'

// const GATSBY_RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

// if (typeof GATSBY_RECAPTCHA_KEY === 'undefined') {
//   throw new Error(`
//   Env var GATSBY_RECAPTCHA_KEY is undefined!
//   You probably forget to set it in your Netlify build environment variables.
//   `)
// }

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
export default function ContactForm() {
  const [state, setState] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    isLoading: false,
  })
  //const recaptchaRef = React.createRef()
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState({ isLoading: true })
    const form = e.target
    //const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        // 'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  const { name, email, phone, message } = state

  return (
    <Form
      name="contact"
      method="post"
      action="/success/"
      data-netlify="true"
      // data-netlify-recaptcha="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out if you're human:
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <div>
        <Label htmlFor="name">
          Name:
          <div>
            <Input
              // placeholder="Name (required)"
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
              id="name"
              required
            />
          </div>
        </Label>
      </div>
      <br />
      <div>
        <Label htmlFor="email">
          Email:
          <div>
            <Input
              // placeholder="Email (required)"
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              id="email"
              required
            />
          </div>
        </Label>
      </div>
      <br />
      {/* <div>
          <Label htmlFor="phone">
            Phone:
            <div>
              <Input
                // placeholder="Phone (required)"
                type="tel"
                name="phone"
                value={phone}
                onChange={this.handleChange}
                id="phone"
                // required
              />
            </div>
          </Label>
        </div>
        <br /> */}
      <div>
        <Label htmlFor="message">
          Message:
          <div className="control">
            <Textarea
              // placeholder="Message (required)"
              type="message"
              name="message"
              value={message}
              onChange={handleChange}
              id="message"
              required
            />
          </div>
        </Label>
      </div>
      <br />
      {/* <div>
        <Recaptcha ref={recaptchaRef} sitekey={GATSBY_RECAPTCHA_KEY} />
      </div>
      <br /> */}
      <div>
        <Button
          disabled={state.isLoading}
          // disabled={this.state['g-recaptcha-response'] ? false : true}
          type="submit"
          value="Send Message"
        >
          {state.isLoading ? 'Sending' : 'Send'}
        </Button>
      </div>
      <br />
      <div>
        <Button type="reset" value="Clear">
          Clear
        </Button>
      </div>
    </Form>
  )
}

//TODO: formik validation examples
//https://www.gatsbyjs.org/docs/adding-forms/

const Input = styled.input`
  min-width: 250px;
  min-height: 30px;
  padding: 0.5em;
  color: rgb(41, 55, 66);
  font-family: inherit;
  font-size: 1.25em;
  background: rgba(139, 139, 139, 0.3);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0.75em;
`

const Textarea = styled.textarea`
  min-width: 250px;
  min-height: 80px;
  padding: 0.5em;
  color: rgb(41, 55, 66);
  font-family: inherit;
  font-size: 1.25em;
  background: rgba(139, 139, 139, 0.3);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0.75em;
`

const Label = styled.label`
  min-width: 250px;
  font-weight: 300;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  font-size: 0.85em;
  /* color: rgb(57, 75, 89); */
  margin-bottom: 0.25em;
  margin-top: 1em;
`

const Button = styled.button`
  background: #ebebeb;
  text-decoration: none;
  border: 0;
  border-radius: 0.1875rem;
  color: #555555;
  line-height: 1.625;
  padding: 0.5rem 1rem;
  display: flex;
  &:disabled {
    color: #b1b1b1;
    background: #ebebeb;
  } /* margin: 1em 0; */
  /* margin: 1em auto; */
`
const Form = styled.form`
  /* margin: 0 auto; */
`
